.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
}
.line {
  border: 0.5px solid #878787;
  width: 0px;
  height: 100%;
}
.wrapperLeft {
  display: flex;
  flex-direction: column;
  width: 35%;
  text-align: right;
  padding: 10vw 0;
}
.wrapperRight {
  display: flex;
  flex-direction: column;
  padding: 10vw 0;
}
.shortLine {
  width: 0px;
  height: 200px;
  border: 1px solid #7b7979;
  margin-top: -100px;
}
.icon {
  text-align: center;
  margin: 15px 0;
}
.normalText {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
}
.rotateWrapper {
  display: flex;
}
.rotate {
  transform: rotate(270deg);
  height: 30px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 275;
  font-size: 17px;
  line-height: 26px;
  margin-top: 50px;
  letter-spacing: 0.13em;
  text-transform: uppercase;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .shortLine,
  .line,
  .rotate {
    display: none;
  }

  .wrapper {
    flex-direction: column;
    height: auto;
  }
  .wrapperLeft {
    width: 90%;
    padding: 40px 0 15px 0;
    margin: 0 auto;
  }
  .normalText {
    text-align: left;
  }
  .wrapperRight {
    width: 90%;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .shortLine {
    margin: -100px 0px 0 20px;
  }
  .wrapperLeft {
    width: 50%;
  }
}
