body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
p,
h1,
h4,
a {
  margin: 0;
}
h4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 80%;
  letter-spacing: 0.02em;
  text-transform: capitalize;
}
h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 48px;
}
.white {
  color: #ffffff;
}
.gray {
  color: #626262;
}
.darkGray {
  color: #414141;
}
.white2 {
  color: #eaeaea;
}
.dark {
  color: #242424;
}
.purple {
  color: #4a3053;
}
.font47 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 47px;
  line-height: 55px;
}
.font45 {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  line-height: 53px;
  text-align: center;
  letter-spacing: 0.02em;
}
.font34 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 51px;
}
.font24 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.015em;
}
.font17 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.01em;
}
.font17P {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.01em;
}
.font17L {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 275;
  font-size: 17px;
  line-height: 26px;
  letter-spacing: 0.015em;
}
.font15 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 27px;
}
.font16 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 275;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.045em;
}
.textCenter {
  text-align: center;
}
.capitalize {
  text-transform: capitalize;
  margin-bottom: 20px;
}
.textRight {
  align-items: flex-end;
}
.noWrap {
  overflow-x: hidden;
}
a {
  text-decoration: none;
  color: inherit;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
}
.small {
  text-transform: lowercase;
}
span {
  font-weight: bold;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .font47 {
    font-size: 35px;
    line-height: 45px;
  }
  .font24 {
    font-size: 20px;
  }
  h1 {
    font-size: 25px;
    line-height: 35px;
    text-align: center;
  }
  .font45 {
    font-size: 28px;
    line-height: 35px;
  }
  .font17L {
    font-size: 15px;
    line-height: 22px;
  }
  .font34 {
    font-size: 25px;
    line-height: 35px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .font45 {
    font-size: 35px;
    line-height: 45px;
  }
}
