.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 70px;
  align-items: center;
}
.links {
  display: flex;
  gap: 40px;
}
.menu {
  display: none;
}
.menuWrapper {
  display: flex;
  flex-direction: column;
  text-align: right;
  img {
    width: 25px;
    margin-left: auto;
    margin-top: 15px;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .wrapper {
    padding: 0 20px;
    align-items: flex-start;
    justify-content: baseline;
  }
  .menu {
    display: flex;
  }
  .links {
    flex-direction: column;
    gap: 5px;
  }
  .icon {
    width: 100px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .wrapper {
    padding: 0 20px;
  }
}
