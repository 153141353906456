.wrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  background: url('../../../public/icons/backgroundAdmin.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.icon {
  width: 35px;
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
}
.logout {
  width: 35px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.box {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  input {
    background: #f6f5f8;
    box-shadow: 0px 4px 10px rgba(170, 170, 170, 0.08);
    border-radius: 12px;
    height: 5vh;
    width: 20vw;
    border: none;
    padding-left: 10px;
  }
  input:focus {
    outline-color: #5d4978;
  }
}
.buttonWrapper {
  display: flex;
  flex-direction: column;
  width: 10vw;
  margin: 0 auto;
  gap: 20px;
}
.button {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  height: 5vh;
  width: 10vw;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #000000;
  cursor: pointer;
}
.button:hover {
  transform: scale(1.2);
}
.jobWrapper {
  display: flex;
  flex-direction: column;
  width: 20vw;
  gap: 20px;
  margin: 0 auto;
  align-items: center;
  input,
  select {
    background: #f6f5f8;
    box-shadow: 0px 4px 10px rgba(170, 170, 170, 0.08);
    border-radius: 12px;
    height: 5vh;
    width: 20vw;
    border: none;
    padding-left: 10px;
  }
  input:focus {
    outline-color: #5d4978;
  }
  select {
    width: 20.5vw;
    color: gray;
  }
}
.workWrapper {
  display: flex;
  gap: 100px;
  margin: 0 auto;
}
.workContainer {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin: 0 auto;
  align-items: center;
}
.text {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 47px;
  line-height: 55px;
  text-transform: uppercase;
  color: #ffffff;
}
