.copyRight {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.015em;
  text-transform: capitalize;
  color: #4a3053;
  display: flex;
  align-items: center;
}
.wrapper {
  display: flex;
  justify-content: center;
  gap: 10vw;
  padding: 90px 0;
}
.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.icons {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  img {
    width: 25px;
  }
}
.iconWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .icon {
    display: none;
  }
  .wrapper {
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .copyRight {
    grid-column: 1 / 3;
  }
  .iconWrapper {
    grid-column: 1 / 3;
    gap: 10px;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1439px) {
  .wrapper {
    padding: 50px 10px 0 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .iconWrapper {
    margin-left: 15px;
    grid-column: 1 / 2;
    margin-top: -150px;
    justify-content: flex-start;
  }
  .icon {
    margin-left: 100px;
  }
}
