.wrapper {
  width: 100vw;
  background-color: #5d4978;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text {
  width: 70%;
  margin: 0 auto 50px auto;
}
.icon {
  margin-top: -50px;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .icon {
    width: 100px;
    margin-top: -30px;
  }
  .text {
    width: 90%;
    margin: 0 auto 30px auto;
  }
  .wrapper {
    margin-top: 30px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .icon {
    width: 120px;
    margin-top: -30px;
  }
  .text {
    width: 90%;
  }
}
