.wrapper {
  display: flex;
  width: 100vw;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
}
.line {
  border: 0.5px solid #878787;
  width: 0px;
  height: 135vh;
}
.wrapperLeft {
  display: flex;
  align-items: end;
  img {
    margin-top: 400px;
  }
}
.wrapperRight {
  width: 70%;
  padding-left: 100px;
  height: 100%;
}
.box {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #f4f5f8;
  border-radius: 12px;
  padding: 20px;
  z-index: 999;
  background-color: #ffffff;
}
.littleBox {
  background: #f6f5f8;
  border-radius: 8px;
  padding: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 129.5%;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: capitalize;
  color: #5d4978;
  width: 40%;
}
.title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 129.5%;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: capitalize;
  color: #000000;
}
.littleBoxWrapper {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
}
.text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 129.5%;
  letter-spacing: 0.015em;
  text-transform: capitalize;
  color: #242424;
  margin-top: 15px;
  width: 80%;
  height: 40px;
}
.locationWrapper {
  display: flex;
  gap: 5px;
  margin-bottom: 15px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 129.5%;
  letter-spacing: 0.015em;
  text-transform: capitalize;
  color: #4c4c4c;
}
.button {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 129.5%;
  text-align: right;
  letter-spacing: 0.015em;
  text-transform: capitalize;
  color: #5d4978;
  cursor: pointer;
}
.boxWrapper {
  display: grid;
  grid-template-columns: repeat(4, 17vw);
  width: 100vw;
  margin-left: -20%;
  gap: 40px;
}
.titleBig {
  margin-top: 70px;
  margin-bottom: 65px;
  text-align: center;
  margin-left: -30%;
}
.titleLast {
  margin: 150px 0 50px 0;
}
.textPart {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 85%;
}
.textWrapper {
  margin-bottom: 150px;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .wrapperLeft {
    display: none;
  }
  .line {
    display: none;
  }
  .wrapperRight {
    padding: 0;
    width: 100%;
  }
  .titleBig {
    margin: 30px 0;
  }
  .boxWrapper {
    flex-direction: column;
    margin-left: 0;
    width: 100%;
    gap: 20px;
  }
  .box {
    width: 70%;
    margin: 0 auto;
  }
  .titleLast {
    margin: 30px 15px;
  }
  .textPart {
    width: 90%;
    margin: 0 15px 30px 15px;
  }
  .textWrapper {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .wrapper {
    align-items: inherit;
  }
  .wrapperRight {
    padding-left: 0;
  }
  .boxWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: -30%;
  }
  .box {
    width: 60%;
  }
  .titleLast {
    margin: 100px 0 50px 0;
  }
  .line {
    height: 200vh;
  }
  .wrapperLeft {
    align-items: inherit;
  }
}
@media screen and (min-width: 1023px) and (max-width: 1439px) {
  .wrapperRight {
    padding-left: 0;
  }
  .boxWrapper {
    margin-left: -35%;
    gap: 20px;
  }
  .box {
    width: 18%;
  }
  .titleBig {
    margin-left: 0;
  }
  .titleLast {
    margin: 100px 0 50px 0;
  }
  .line {
    height: 180vh;
  }
}
