.wrapper {
  background: url('../../background.png');
  width: 100vw;
  height: 100vh;
}
.zUp {
  z-index: 999;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.button {
  background: #fdfdfd;
  border-radius: 35px;
  padding: 14px 95px;
  width: 10%;
  display: flex;
  justify-content: center;
  margin-top: 56px;
}
.line {
  width: 99px;
  height: 3px;
  background: #ffffff;
  margin: 20px 0;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
  justify-content: center;
  margin: 0 auto;
}
.title {
  width: 50%;
}
.text {
  width: 80%;
  margin: 0 auto;
}

.Animation2 {
  animation-fill-mode: forwards;
  animation: buble2 5s ease-in-out infinite reverse;
  background-color: transparent;
  height: 100vh;
  overflow: hidden;
  opacity: 0.1;
  position: absolute;
  width: 100%;
}

.AnimationDiv {
  animation: buble 5s ease-in-out infinite;
  background-color: transparent;
  height: 80vh;
  overflow: hidden;
  opacity: 0.1;
  position: absolute;
  width: 100%;
}
@keyframes buble {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.2;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes buble2 {
  0% {
    transform: scale(0.5);
    opacity: 1;
    transform: rotate(180deg);
  }
  50% {
    transform: scale(1);
    opacity: 0.2;
    transform: rotate(180deg);
  }
  100% {
    transform: scale(0.5);
    opacity: 1;
    transform: rotate(180deg);
  }
}
.animationWrapper {
  width: 100vw;
  display: flex;
  position: relative;
}
.ani {
  background: linear-gradient(
      180deg,
      #837bd9 0%,
      #613fe3 31.59%,
      #f0544f 56.06%,
      #f0c808 84.53%
    ),
    linear-gradient(180deg, #f0c808 0%, #b0fe76 37.37%);
  mix-blend-mode: normal;
  filter: blur(250px);
  transform: rotate(-137.47deg);
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .container {
    padding: 0;
  }
  .title {
    width: 100%;
    margin: 40px 0;
  }
  .line {
    display: none;
  }
  .text {
    width: 90%;
  }
  .Animation2 {
    margin-top: 100px;
  }
}
